import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PageNotFound from "./pages/404";
import PageLanding from "./pages/landing";
import ThemeManager from "./middleware/ThemeManager";
import { SnackbarProvider } from "notistack";
import { RecoilRoot } from "recoil";
import SplashScreen from "./components/SplashScreen";
import ModalProvider from "mui-modal-provider";
import PageAbout from "./pages/about";

import { AppConfig } from "./config";
import PageComingSoon from "./pages/coming-soon";
import PageMaintenance from "./pages/maintenance";
// Defines the routes and any middlewares needed for the application to function.
import { Analytics } from "@vercel/analytics/react";
function App() {
  const params = new URLSearchParams(window.location.search);
  const forceMode = params.get("forceMode") === "live";
  return (
    <RecoilRoot>
      <Analytics />
      <ThemeManager>
        <SnackbarProvider
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        >
          <ModalProvider>
            <Router>
              <Routes>
                {AppConfig.mode === "coming-soon" && (
                  <Route element={<PageComingSoon />} path="*" />
                )}
                {AppConfig.mode === "maintenance" && (
                  <Route element={<PageMaintenance />} path="*" />
                )}
                {(AppConfig.mode === "live" || forceMode) && (
                  <>
                    <Route element={<PageLanding />} path="/" />
                    {/* Matches with the root path for showing landing page  */}
                    <Route element={<PageAbout />} path="/about" />

                    {/* If the path doesn't match anything, we end up showing 404 */}
                    <Route element={<PageNotFound />} path="/*" />
                  </>
                )}
              </Routes>
            </Router>
          </ModalProvider>
        </SnackbarProvider>
      </ThemeManager>
    </RecoilRoot>
  );
}

export default App;
