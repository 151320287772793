import { Stack, Typography, useTheme } from "@mui/material";
import StaticHeader from "../landing/components/header";
import StaticFooter from "../landing/components/footer";
import { motion } from "framer-motion";
import { IMG_ARROW } from "../../assets";

export default function PageAbout() {
  const theme = useTheme();
  return (
    <Stack
      spacing={"100px"}
      sx={{
        background: theme.palette.background.default,
        overflow: "hidden",
      }}
    >
      <StaticHeader />

      <Stack alignItems={"center"}>
        <Stack sx={{ maxWidth: "1320px", width: "100%" }} spacing={"24px"}>
          <Stack sx={{ py: "24px", position: "relative", zIndex: 0 }}>
            <div
              style={{
                background: "#3CD7CD",
                height: "500px",
                width: "500px",
                position: "absolute",
                filter: "blur(350px)",
                left: -0,
                zIndex: -1,
                pointerEvents: "none",
              }}
            />
            <motion.img
              src={IMG_ARROW}
              style={{ position: "absolute", rotate: "90deg" }}
              initial={{ left: -100, top: 0, opacity: 0 }}
              animate={{ left: 1000, top: 1000, opacity: [0, 1, 0] }}
              transition={{ duration: 30, repeat: Infinity }}
            />
            <Typography
              sx={{
                fontSize: 48,
                fontWeight: 500,
                fontFamily: "Space Grotesk",
                letterSpacing: "-2.4px",
                [theme.breakpoints.down("md")]: {
                  fontSize: "36px",
                  textAlign: "center",
                },
              }}
            >
              About
            </Typography>
            <Typography
              sx={{
                fontSize: 22,
                fontWeight: 400,
                fontFamily: "Space Grotesk",
                letterSpacing: "0px",
                [theme.breakpoints.down("md")]: {
                  fontSize: "18px",
                  textAlign: "center",
                  paddingRight: "24px",
                  paddingLeft: "24px",
                },
              }}
            >
              Welcome to our trading course membership, where passion meets proficiency in the world of trading. Designed for beginners eager to venture into the dynamic realm of financial markets, our meticulously crafted program is your gateway to becoming a successful trader, even if you're starting with zero knowledge.
              <br />
              <br />
              At our core, we believe in empowering individuals with the essential tools and knowledge needed to navigate the complexities of trading. Our comprehensive curriculum begins with a strong foundation in fundamentals and fundamental bias, ensuring you grasp the underlying principles that drive market movements.
              <br />
              <br />
              Moving forward, we delve into the realm of news analysis, where you'll learn to decipher market reactions to global events and economic indicators. Technical analysis forms another crucial pillar of our teachings, equipping you with the skills to interpret charts and identify lucrative trading opportunities with confidence.
              <br />
              <br />
              But we don't stop there. Our membership unveils a specialised strategy honed through years of experience and market scrutiny. This battle-tested approach is the culmination of proven techniques and strategies that work, offering you a roadmap to profitable trading.
            </Typography>
          </Stack>

          <Stack sx={{ py: "24px" }}>
            <Typography
              variant="h2"
              sx={{
                fontSize: 48,
                fontWeight: 500,
                fontFamily: "Space Grotesk",
                letterSpacing: "-2.4px",
                [theme.breakpoints.down("md")]: {
                  fontSize: "36px",
                  textAlign: "center",
                },
              }}
            >
              Reports & Analysis
            </Typography>
            <Typography
              sx={{
                fontSize: 22,
                fontWeight: 400,
                fontFamily: "Space Grotesk",
                letterSpacing: "0px",
                [theme.breakpoints.down("md")]: {
                  fontSize: "18px",
                  textAlign: "center",
                  paddingRight: "24px",
                  paddingLeft: "24px",
                },
              }}
            >
At the heart of our curriculum lies our commitment to staying ahead of the curve. Gain exclusive access to our weekly fundamental reports, meticulously crafted to provide you with in-depth analysis of currencies and commodities. These reports serve as your roadmap, guiding you through the intricate landscape of market fundamentals and helping you make informed trading decisions.
              <br />
              <br />
              But we don't stop there. Stay informed and stay ahead with our daily news reports, where our expert analysts dissect market-moving events and anticipate potential outcomes. From economic releases to geopolitical developments, we provide you with a comprehensive overview of what to expect and how to navigate the resulting market volatility.

            </Typography>
          </Stack>



          <Stack sx={{ py: "24px" }}>
            <Typography
              variant="h2"
              sx={{
                fontSize: 48,
                fontWeight: 500,
                fontFamily: "Space Grotesk",
                letterSpacing: "-2.4px",
                [theme.breakpoints.down("md")]: {
                  fontSize: "36px",
                  textAlign: "center",
                },
              }}
            >
              Live Calls
            </Typography>
            <Typography
              sx={{
                fontSize: 22,
                fontWeight: 400,
                fontFamily: "Space Grotesk",
                letterSpacing: "0px",
                [theme.breakpoints.down("md")]: {
                  fontSize: "18px",
                  textAlign: "center",
                  paddingRight: "24px",
                  paddingLeft: "24px",
                },
              }}
            >
             Engage in live calls, where real-time analysis and discussions further enrich your learning experience, cultivating a community of like-minded individuals committed to mastering the art of trading.
              <br />
              <br />
              Join us on this transformative journey and unlock your potential as a proficient and profitable trader. Embrace the opportunity to learn, grow, and thrive in the ever-evolving world of trading with our comprehensive membership program.
            </Typography>
          </Stack>

          <Stack sx={{ py: "24px" }}>
            <Typography
              variant="h2"
              sx={{
                fontSize: 48,
                fontWeight: 500,
                fontFamily: "Space Grotesk",
                letterSpacing: "-2.4px",
                [theme.breakpoints.down("md")]: {
                  fontSize: "36px",
                  textAlign: "center",
                },
              }}
            >
              Our Community
            </Typography>
            <Typography
              sx={{
                fontSize: 22,
                fontWeight: 400,
                fontFamily: "Space Grotesk",
                letterSpacing: "0px",
                [theme.breakpoints.down("md")]: {
                  fontSize: "18px",
                  textAlign: "center",
                  paddingRight: "24px",
                  paddingLeft: "24px",
                },
              }}
            >
             Learning is not a solitary journey. As a member, you'll join our vibrant Discord community, a hub of collaboration and support where educators and students converge to share insights, discuss market trends, and foster growth together. 

            </Typography>
          </Stack>
        </Stack>
        
      </Stack>
      <StaticFooter />
    </Stack>
  );
}
