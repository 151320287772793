import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import {
  IC_BLOOMBERG,
  IC_FORBES,
  IC_MARKETWATCH,
  IC_TRADINGVIEW,
  IC_WALLSTREET,
  IC_YAHOO,
} from "../../../assets";
import Marquee from "react-fast-marquee";

export default function StaticBusinesses() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <Stack
      alignItems={"center"}
      sx={{
        position: "relative",
        pt: isDesktop ? "60px" : 0,
        overflow: "visible",
        zIndex: 10,
      }}
    >
      {/* <Typography
        color={"secondary"}
        sx={{
          fontFamily: "Helvetica-Medium",
          fontSize: 24,
          letterSpacing: "2.4px",
          textTransform: "uppercase",
          [theme.breakpoints.down("md")]: {
            fontSize: 16,
            textAlign: "center",
          },
        }}
      >
        POWERING 20K+ BUSINESSES GLOBALLY
      </Typography> */}

      <Marquee
        direction="right"
        style={{
          zIndex: 10,
          marginTop: isDesktop ? "30px" : "0px",
        }}
      >
        {[
          IC_WALLSTREET,
          IC_YAHOO,
          IC_MARKETWATCH,
          IC_MARKETWATCH,
          IC_BLOOMBERG,
          IC_BLOOMBERG,
          IC_FORBES,
          IC_WALLSTREET,
          IC_YAHOO,
          IC_FORBES,
        ].map((ic) => (
          <Stack
            sx={{
              width: "200px",
              height: "95px",
              background: "rgba(14, 11, 52, 0.25)",
              borderRadius: "18px",
              zIndex: 10,
              margin: isDesktop ? "28px" : "12px",
              border: "1px solid rgba(60, 215, 205, 0.15)",
            }}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <img
              src={ic}
              style={{
                objectFit: "contain",
              }}
            />
          </Stack>
        ))}
      </Marquee>
      <Marquee
        style={{
          zIndex: 1,
        }}
      >
        {[
          IC_WALLSTREET,
          IC_YAHOO,
          IC_MARKETWATCH,
          IC_MARKETWATCH,
          IC_BLOOMBERG,
          IC_BLOOMBERG,
          IC_FORBES,
          IC_WALLSTREET,
          IC_YAHOO,
          IC_FORBES,
        ].map((ic) => (
          <Stack
            sx={{
              width: "200px",
              height: "95px",
              background: "rgba(14, 11, 52, 0.25)",
              borderRadius: "18px",
              zIndex: 10,
              margin: isDesktop ? "28px" : "12px",
              border: "1px solid rgba(60, 215, 205, 0.15)",
            }}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <img
              src={ic}
              style={{
                objectFit: "contain",
              }}
            />
          </Stack>
        ))}
      </Marquee>
    </Stack>
  );
}
