import { Stack, useTheme } from "@mui/material";
import StaticHeader from "./components/header";
import StaticHero from "./components/hero";
import StaticAbout from "./components/about";
import StaticFooter from "./components/footer";
import StaticBusinesses from "./components/businesses";
import { Helmet } from "react-helmet";
import StaticDownloads from "./components/downloads";
const ogData = {
  title: "Alpha Trading Strategies",
  description:
    "Enhance your MT5 Trading Experience with our advanced news indicator.",
  url: "https://alphatradingstrategies.io",
  image: "https://alphatradingstrategies.io/brand/logo.png",
  siteName: "Alpha Trading Strategies",
};
export default function PageLanding() {
  const theme = useTheme();
  return (
    <Stack
      spacing={"100px"}
      sx={{
        background: theme.palette.background.default,
        overflow: "hidden",
      }}
    >
      <Helmet>
        <title>{ogData.title}</title>
        <meta name="description" content={ogData.description} />
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={ogData.title} />
        <meta property="og:description" content={ogData.description} />
        <meta property="og:url" content={ogData.url} />
        <meta property="og:image" content={ogData.image} />
        <meta property="og:site_name" content={ogData.siteName} />
      </Helmet>
      <StaticHeader />
      <StaticHero />
      <StaticAbout />
      <StaticDownloads />
      <StaticBusinesses />
      <StaticFooter />
    </Stack>
  );
}
