import {
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import StaticAboutCard from "./aboutCard";
import {
  IC_ONLINE_MEETING,
  IC_ONLINE_TRAINING,
  IC_SCHOOL,
  IMG_ARROW,
} from "../../../assets";
import { motion, useScroll } from "framer-motion";

export default function StaticDownloads() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const scrollInfo = useScroll();
  return (
    <Stack
      alignItems={"center"}
      spacing={"100px"}
      sx={{ position: "relative" }}
    >
      <div id="downloads" />
      <Stack
        spacing={isDesktop ? "30px" : "15px"}
        sx={{
          maxWidth: "860px",
          width: "100%",
          zIndex: 1,
        }}
      >
        <Typography
          sx={{
            fontSize: 48,
            fontWeight: 500,
            fontFamily: "Space Grotesk",
            letterSpacing: "-2.4px",
            [theme.breakpoints.down("md")]: {
              fontSize: "36px",
              textAlign: "center",
            },
          }}
        >
          Downloads
        </Typography>
        {/* Cards  */}
        <Stack
          spacing="16px"
          direction={isDesktop ? "row" : "column"}
          sx={{
            pt: "30px",
            [theme.breakpoints.down("md")]: {
              alignItems: "center",
            },
          }}
        >
          <StaticAboutCard
            img={IC_SCHOOL}
            title="ATS News EA"
            bulletPoints={[
              "Live News on MT5 Charts",
              "Timeline Visualization",
              "Automatic Timezone Detection",
              "Filter by News Impact",
              "Backtest compatibility",
            ]}
            btnText="Download"
          />

          <StaticAboutCard
            img={IC_ONLINE_MEETING}
            title="FX Trader Terminal"
            bulletPoints={[
              "One-click Trading Interface",
              "Real-time Risk Dashboard",
              "Advanced Order & Calculations",
              "Customizable Multi-chart Layout",
              "Automated Trade Journaling",
            ]}
            btnText="Coming Soon"
            btnColor="info"
          />

          <StaticAboutCard
            img={IC_ONLINE_TRAINING}
            title="I AM Strategy EA - Pairs Trading"
            bulletPoints={[
              "Advanced correlation analysis",
              "Non-toxic execution",
              "Market-neutral strategy",
              "Intelligent risk management",
              "Automated trade management",
            ]}
            btnText="Coming Soon"
            btnColor="info"
          />
        </Stack>

        {/* CTA */}
        <Stack>
          <Stack sx={{ alignSelf: "center", position: "relative" }}>
            <div
              style={{
                background: "#3CD7CD",
                height: "500px",
                width: "500px",
                position: "absolute",
                filter: "blur(350px)",
                zIndex: 0,
                alignSelf: "center",
                pointerEvents: "none",
                opacity: isDesktop ? 1 : 0.4,
              }}
            />
          </Stack>
        </Stack>
      </Stack>
      <motion.img
        src={IMG_ARROW}
        style={{ position: "absolute" }}
        initial={{ left: -100, bottom: 0, opacity: 0 }}
        animate={{ left: 1000, bottom: 1000, opacity: [0, 1, 0] }}
        transition={{ duration: 30, repeat: Infinity }}
      />
      <motion.img
        src={IMG_ARROW}
        style={{ position: "absolute", rotate: "180deg" }}
        initial={{ right: -100, bottom: 0, opacity: 0 }}
        animate={{ right: 1000, bottom: -1000, opacity: [0, 1, 0] }}
        transition={{ duration: 30, repeat: Infinity }}
      />
    </Stack>
  );
}
