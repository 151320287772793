import { Button, Stack, Typography } from "@mui/material";
import { Check } from "@mui/icons-material";
import { motion } from "framer-motion";

export default function StaticAboutCard(props: {
  img: any;
  title: string;
  bulletPoints: string[];
  btnText: string;
  btnClick?: () => void;
  btnColor?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
}) {
  return (
    <motion.div
      style={{ display: "flex" }}
      initial={{ scale: 1.2 }}
      whileInView={{ scale: 1 }}
      viewport={{ once: true }}
      transition={{ delay: 0.1 }}
    >
      <Stack
        spacing={"35px"}
        sx={{
          borderRadius: "18px",
          border: "1px solid rgba(60, 215, 205, 0.15)",
          background: "rgba(14, 11, 52, 0.25)",
          backdropFilter: "blur(12.5px)",
          padding: "32px",
          minWidth: "274px",
        }}
      >
        <img
          src={props.img}
          alt=""
          style={{ width: "48px", height: "48px", margin: "8px" }}
        />

        <Stack spacing={"5px"} flex={1}>
          <Typography
            sx={{
              fontSize: 24,
              fontWeight: "500",
              fontFamily: "Space Grotesk",
              paddingBottom: "10px",
              letterSpacing: "-1.2px",
            }}
          >
            {props.title}
          </Typography>
          {props.bulletPoints.map((bp) => (
            <Stack direction={"row"} spacing={"10px"} alignItems={"center"}>
              <Check color="secondary" />
              <Typography
                sx={{ fontSize: 18, fontFamily: "Helvetica", opacity: 0.6 }}
              >
                {bp}
              </Typography>
            </Stack>
          ))}
        </Stack>

        <Button
          color={props.btnColor}
          variant="contained"
          sx={{ mt: "47px", fontSize: "16px", width: "209px" }}
          onClick={props.btnClick}
        >
          {props.btnText}
        </Button>
      </Stack>
    </motion.div>
  );
}
