import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { IMG_ARROW, LOGO, NAV_LOGO } from "../../assets";
import { motion } from "framer-motion";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet";

export default function PageComingSoon() {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Stack
      sx={{
        background: theme.palette.background.default,
        overflow: "hidden",
        height: "100vh",
        position: "relative",
        zIndex: 0,
      }}
      alignItems={"center"}
    >
      <Helmet>
        <title>Coming Soon - Alpha Trading University</title>
      </Helmet>
      <Stack
        alignItems={"center"}
        position={"absolute"}
        justifyContent={"center"}
        sx={{ maxWidth: "1320px", width: "100%", pt: "48px" }}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          sx={{ maxWidth: "1320px", width: "100%" }}
          justifyContent={"space-between"}
        >
          <motion.a
            href="/"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
          >
            {/* Site Logo: Desktop  */}
            {!isMobile && (
              <img src={NAV_LOGO} alt="Alpha Trading University Logo" />
            )}
            {isMobile && (
              <img
                src={LOGO}
                alt="Alpha Trading University Logo"
                style={{ height: "40px", objectFit: "contain" }}
              />
            )}
          </motion.a>
          {/* Nav Buttons / Menu  */}
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
          ></motion.div>
        </Stack>
      </Stack>
      <Stack
        flex={1}
        alignItems={"center"}
        justifyContent={"center"}
        spacing={"100px"}
        sx={{ position: "relative", zIndex: 0 }}
      >
        <Stack
          // We inverse the direction on mobiles, so the logo stays above the content.
          direction={!isMobile ? "row" : "column-reverse"}
          position={"relative"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{
            maxWidth: "1320px",
            width: "100%",
            zIndex: 5,
          }}
          spacing={"45px"}
        >
          {/* Column Left: Text, Description, and Rating  */}
          <motion.filter
            initial={{ filter: "blur(10px)" }}
            whileInView={{ filter: "blur(0px)" }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <Stack alignItems={!isMobile ? "flex-start" : "center"}>
              <Typography
                variant="h1"
                sx={{
                  fontWeight: 500,
                  fontFamily: "Space Grotesk",
                  wordWrap: "normal",
                  fontSize: "78px",
                  textAlign: "left",
                  px: "0px",
                  [theme.breakpoints.down("md")]: {
                    fontSize: "38px",
                    textAlign: "center",
                    px: "24px",
                  },
                }}
              >
                A New Chapter is About to{" "}
                <span style={{ color: theme.palette.secondary.main }}>
                  Begin.
                </span>
              </Typography>

              {/* Rating  */}
              <Stack
                direction={"row"}
                spacing={"16px"}
                sx={{ pt: !isMobile ? "45px" : "22px" }}
                alignItems={"center"}
              >
                <Typography
                  sx={{
                    fontSize: !isMobile ? "20px" : "16px",
                    fontWeight: "500",
                    fontFamily: "Helvetica-Medium",
                  }}
                >
                  Coming Soon!
                </Typography>
              </Stack>
            </Stack>
          </motion.filter>

          {/* Column Right, For Logo */}
          <Stack>
            <motion.img
              src={LOGO}
              style={{
                height: !isMobile ? "466px" : "202px",
                objectFit: "contain",
              }}
              initial={{ translateY: 200 }}
              whileInView={{ translateY: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 1 }}
            />
          </Stack>

          {/* Glow effects  */}
          <div
            style={{
              background: "#3CD7CD",
              height: "500px",
              width: "500px",
              position: "absolute",
              filter: "blur(350px)",
              left: -250,
              zIndex: -1,
              pointerEvents: "none",
              opacity: !isMobile ? 0.7 : 0,
            }}
          />
          <div
            style={{
              background: "#3CD7CD",
              height: "500px",
              width: "500px",
              position: "absolute",
              filter: "blur(450px)",
              right: -400,
              zIndex: -1,
              pointerEvents: "none",
              opacity: !isMobile ? 0.7 : 0.4,
            }}
          />
          <motion.img
            src={IMG_ARROW}
            style={{ position: "absolute", rotate: "90deg" }}
            initial={{ left: -100, top: 0, opacity: 0 }}
            animate={{ left: 1000, top: 1000, opacity: [0, 1, 0] }}
            transition={{ duration: 30, repeat: Infinity }}
          />
          <Stack
            sx={{
              position: "absolute",
              alignItems: "center",
              justifyContent: "center",
              zIndex: -1,
              overflow: "visible",
            }}
          >
            <motion.div
              style={{
                background: "#3CD7CD",
                height: "500px",
                width: "500px",
                position: "absolute",
                filter: "blur(250px)",
                zIndex: 0,
                bottom: -500,
                pointerEvents: "none",
              }}
              animate={{ opacity: [0, 1, 0.4, 0.8, 0.2, 1] }}
              transition={{
                duration: 30,
                repeat: Infinity,
                repeatType: "reverse",
              }}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
