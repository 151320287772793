import {
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import StaticAboutCard from "./aboutCard";
import {
  IC_ONLINE_MEETING,
  IC_ONLINE_TRAINING,
  IC_SCHOOL,
  IMG_ARROW,
} from "../../../assets";
import { motion, useScroll } from "framer-motion";
import { Check } from "@mui/icons-material";

// Display next eight news events directly on your chart
// Timeline visualization
// Choose from 2 forex news providers, including ForexFactory calendar
// Automatic GMT time detection
// Currency and impact (low/medium/high) filtering options
// Mobile notifications before news events (MT5 Notification Service)
// Automatically disable and re-enable EAs around news events
// Store important values in global variables for third-party robot integration
// Backtest compatibility (with certain data providers)

const KEY_FEATURES = [
  "Display next eight news events directly on your chart.",
  "Timeline visualization.",
  "Choose from 2 forex news providers, including ForexFactory calendar.",
  "Automatic GMT time detection.",
  "Currency and impact (low/medium/high) filtering options.",
  "Mobile notifications before news events (MT5 Notification Service).",
  "Automatically disable and re-enable EAs around news events.",
  "Store important values in global variables for third-party robot integration.",
  "Backtest compatibility (with certain data providers).",
];

export default function StaticAbout() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const scrollInfo = useScroll();
  return (
    <Stack
      alignItems={"center"}
      spacing={"100px"}
      sx={{ position: "relative" }}
    >
      <Stack
        spacing={isDesktop ? "30px" : "15px"}
        sx={{
          maxWidth: "860px",
          width: "100%",
          zIndex: 1,
        }}
      >
        <div id="about" />
        <Typography
          variant="h4"
          component={"div"}
          id="learn-more"
          color={"secondary"}
          sx={{
            fontSize: 24,
            fontWeight: 500,
            fontFamily: "Helvetica-Medium",
            [theme.breakpoints.down("md")]: {
              fontSize: "16px",
              textAlign: "center",
            },
          }}
        >
          Why ATS News EA?
        </Typography>
        <Typography
          sx={{
            fontSize: 36,
            fontWeight: 400,
            fontFamily: "Space Grotesk",
            letterSpacing: "-1.08px",
            [theme.breakpoints.down("md")]: {
              fontSize: "20px",
              textAlign: "center",
              paddingRight: "24px",
              paddingLeft: "24px",
            },
          }}
        >
          In today's forex market, economic news plays a crucial role. Whether
          you're a manual trader preparing for news events or an EA user looking
          to protect your strategy, ATS News EA is your all-in-one solution.{" "}
          <br />
          <br />
          Engage in our live market reports and calls alongside professional
          traders.
        </Typography>

        <Typography
          variant="h4"
          component={"div"}
          id="learn-more"
          color={"secondary"}
          sx={{
            fontSize: 24,
            fontWeight: 500,
            fontFamily: "Helvetica-Medium",
            [theme.breakpoints.down("md")]: {
              fontSize: "16px",
              textAlign: "center",
            },
          }}
        >
          Key Features
        </Typography>

        <Stack spacing={"4px"}>
          {KEY_FEATURES.map((kf) => (
            <Stack direction={"row"} spacing={"10px"} alignItems={"center"}>
              <Check color="secondary" />
              <Typography
                sx={{
                  fontSize: 18,
                  fontFamily: "Helvetica",
                  opacity: 0.6,
                  transition: "all .3s",
                  ":hover": { opacity: 1 },
                }}
              >
                {kf}
              </Typography>
            </Stack>
          ))}
        </Stack>
        <Typography sx={{ fontSize: 20, fontWeight: "600" }}>
          Perfect for Both Manual & EA Traders
        </Typography>

        <Stack spacing={"4px"}>
          <Stack direction={"row"} spacing={"10px"} alignItems={"center"}>
            <Check color="secondary" />
            <Typography
              sx={{
                fontSize: 18,
                fontFamily: "Helvetica",
                opacity: 0.6,
                transition: "all .3s",
                ":hover": { opacity: 1 },
              }}
            >
              Manual Traders: Prepare effectively for upcoming news and events.
            </Typography>
          </Stack>
          <Stack direction={"row"} spacing={"10px"} alignItems={"center"}>
            <Check color="secondary" />
            <Typography
              sx={{
                fontSize: 18,
                fontFamily: "Helvetica",
                opacity: 0.6,
                transition: "all .3s",
                ":hover": { opacity: 1 },
              }}
            >
              EA Users: Protect your strategy from false signals and high
              volatility during news periods.
            </Typography>
          </Stack>
        </Stack>

        <Stack>
          <Typography sx={{ fontSize: 20, fontWeight: "600" }}>
            Developed by Experts for Professionals
          </Typography>
          <Typography sx={{ fontSize: 18, fontWeight: "400", opacity: 0.8 }}>
            ATS News EA is a product of extensive research and development by
            the team at Alpha Trading Strategies. We've created a tool that
            addresses the real needs of professional forex traders on the
            MetaTrader 4 platform.
          </Typography>
        </Stack>

        <Typography
          variant="h4"
          component={"div"}
          id="learn-more"
          color={"secondary"}
          sx={{
            fontSize: 24,
            fontWeight: 500,
            fontFamily: "Helvetica-Medium",
            [theme.breakpoints.down("md")]: {
              fontSize: "16px",
              textAlign: "center",
            },
          }}
        >
          How to Get Your Free ATS News EA
        </Typography>

        <Stack spacing={"4px"}>
          <Stack direction={"row"} spacing={"10px"} alignItems={"center"}>
            <Check color="secondary" />
            <Typography
              sx={{
                fontSize: 18,
                fontFamily: "Helvetica",
                opacity: 0.6,
                transition: "all .3s",
                ":hover": { opacity: 1 },
              }}
            >
              Click on the Download button below.
            </Typography>
          </Stack>
          <Stack direction={"row"} spacing={"10px"} alignItems={"center"}>
            <Check color="secondary" />
            <Typography
              sx={{
                fontSize: 18,
                fontFamily: "Helvetica",
                opacity: 0.6,
                transition: "all .3s",
                ":hover": { opacity: 1 },
              }}
            >
              Enter your email address
            </Typography>
          </Stack>
          <Stack direction={"row"} spacing={"10px"} alignItems={"center"}>
            <Check color="secondary" />
            <Typography
              sx={{
                fontSize: 18,
                fontFamily: "Helvetica",
                opacity: 0.6,
                transition: "all .3s",
                ":hover": { opacity: 1 },
              }}
            >
              Read and agree to the Terms & Conditions and click Submit.
            </Typography>
          </Stack>
          <Stack direction={"row"} spacing={"10px"} alignItems={"center"}>
            <Check color="secondary" />
            <Typography
              sx={{
                fontSize: 18,
                fontFamily: "Helvetica",
                opacity: 0.6,
                transition: "all .3s",
                ":hover": { opacity: 1 },
              }}
            >
              Check your email for the download link.
            </Typography>
          </Stack>
        </Stack>

        <Typography sx={{ fontSize: 20, fontWeight: "600" }}>
          Important Note
        </Typography>

        <Stack spacing={"4px"}>
          <Typography
            sx={{
              fontSize: 18,
              fontFamily: "Helvetica",
              opacity: 0.6,
              transition: "all .3s",
              ":hover": { opacity: 1 },
            }}
          >
            When activating the EA, you will be asked for an email address.
            Please ensure you use the same email address you provided on this
            page when adding the EA to your chart.
          </Typography>
          <Typography
            sx={{
              fontSize: 18,
              fontFamily: "Helvetica",
              opacity: 0.6,
              transition: "all .3s",
              ":hover": { opacity: 1 },
            }}
          >
            Don't miss this opportunity to enhance your trading strategy with
            ATS News EA – get your free copy now!
          </Typography>
        </Stack>
      </Stack>
      <motion.img
        src={IMG_ARROW}
        style={{ position: "absolute" }}
        initial={{ left: -100, bottom: 0, opacity: 0 }}
        animate={{ left: 1000, bottom: 1000, opacity: [0, 1, 0] }}
        transition={{ duration: 30, repeat: Infinity }}
      />
      <motion.img
        src={IMG_ARROW}
        style={{ position: "absolute", rotate: "180deg" }}
        initial={{ right: -100, bottom: 0, opacity: 0 }}
        animate={{ right: 1000, bottom: -1000, opacity: [0, 1, 0] }}
        transition={{ duration: 30, repeat: Infinity }}
      />
    </Stack>
  );
}
